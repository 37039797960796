
import { AppUserClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import AlreadyRegistered from '../components/alreadyregistered.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';
import InfoModal from '@/components/modals/infoModal.vue';

@Options({
    components: {
        AlreadyRegistered
    }
})
export default class RegisterPhone extends Vue {

    languageCredential: OM.LanguageCredentialWithLocation = new OM.LanguageCredentialWithLocation();
    phoneNumber: VM.PhoneNumber = new VM.PhoneNumber();

    created() {
        this.languageCredential.preferredCulture = store.state.loggedUser.preferredCulture;

        if(!this.languageCredential.address)
            this.$router.replace("/shareposition")

        if(store.state.registeredPhoneNumber)
            this.phoneNumber = store.state.registeredPhoneNumber;
    }

    get isDisabled(){
        return !this.phoneNumber.prefix || !this.phoneNumber.number;
    }

    next(){
        this.languageCredential.username = this.phoneNumber.prefix + this.phoneNumber.number;

        AppUserClient.register(this.languageCredential)
        .then( x => {
            StorageServices.setRegisteredPhoneNumber(this.phoneNumber);
            store.state.registeredPhoneNumber = this.phoneNumber;    

            var loggedUser = StorageServices.getLoggedUser();
            loggedUser.identifier = x.identifier;
            loggedUser.username = this.phoneNumber.prefix + this.phoneNumber.number;
            StorageServices.setLoggedUser(loggedUser);
            store.state.loggedUser = loggedUser;

            this.$router.push("insertcode");
        }).catch( ex => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                subtitle: ex.Message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

}
