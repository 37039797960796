import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex_centered column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_localized_text, {
      localizedKey: "app_Seigiaregistrato_question",
      text: "Sei già registrato?",
      class: "fev_text5"
    }),
    _createVNode(_component_router_link, {
      to: "/login",
      class: "bg_brand bg_on_text"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_localized_text, {
          localizedKey: "app_Login",
          text: "Login",
          class: "fev_text5 fw_700"
        })
      ]),
      _: 1
    })
  ]))
}