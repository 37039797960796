import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex_spacebetween_centered column fp_container text-center" }
const _hoisted_2 = { class: "flex_align_centered column mb-5" }
const _hoisted_3 = { class: "flex_centered column text-center" }
const _hoisted_4 = { class: "flex_centered column mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_feven = _resolveComponent("header-feven")
  const _component_localized_text = _resolveComponent("localized-text")
  const _component_form_field = _resolveComponent("form-field")
  const _component_already_registered = _resolveComponent("already-registered")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_header_feven, { class: "mb-4" }),
      _createVNode(_component_localized_text, {
        localizedKey: "app_PerRegistrasiBastaInserireIlTuoNumeroDiTelefonoIlCodiceDiConfermaCheRiceveraiViaSMSELaTuaMail",
        text: "Per registrasi basta inserire il tuo numero di telefono, il codice di conferma che riceverai via SMS e la tua mail.",
        class: "fev_text5 fw_700"
      })
    ]),
    _createVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)), ["prevent","stop"]))
    }, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_form_field, {
          type: "tel",
          prefix: _ctx.phoneNumber.prefix,
          "onUpdate:prefix": _cache[1] || (_cache[1] = ($event: any) => (_ctx.phoneNumber.prefix = $event)),
          modelValue: _ctx.phoneNumber.number,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.phoneNumber.number = $event)),
          required: true,
          placeholderKey: "app_Numero",
          placeholderValue: "Numero"
        }, null, 8, ["prefix", "modelValue"]),
        _createVNode(_component_localized_text, {
          localizedKey: "app_UnaVoltaConfermatoSaraPossibileCambiarloSoloSuRichiestaScrittaAlNostroCustomerCare",
          text: "Una volta confermato sarà possibile cambiarlo solo su richiesta scritta al nostro <a href='mailto:{{mail}}'>Customer Care</a>",
          object: { mail: _ctx.$store.state.consts.config.customerCareContact.emailAddress},
          class: "fev_text7 mt-4"
        }, null, 8, ["object"]),
        _createVNode(_component_localized_text, {
          localizedKey: "app_ProseguendoConLaRegistrazioneAccettoITerminiECondizioneELaPrivacyPolicy",
          text: "Proseguendo con la registrazione accetto i <a target='_blank' href='{{termslink}}'>Termini e Condizione</a> e la <a target='_blank' href='{{privacylink}}'>Privacy Policy</a>",
          object: { termslink: _ctx.$localizationService.getTermsAndConditionsLink(), privacylink: _ctx.$localizationService.getPrivacyPolicyLink() },
          class: "fev_text7 mb-3 mt-5"
        }, null, 8, ["object"])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode("button", {
          type: "submit",
          class: "fev_button bg_brand mb-3",
          disabled: _ctx.isDisabled
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "app_Prosegui",
            text: "Prosegui"
          })
        ], 8, ["disabled"]),
        _createVNode(_component_already_registered)
      ])
    ], 32)
  ]))
}